import React, { useEffect, useRef } from "react"

import { ScreenForm } from './js/components/screens/ScreenForm';
import { EMessageType, EScreen, EState, ErrorType, IMessageCurrentState, IMessageDetectStart, IMessageVerificationStart } from './types';
import { useAppDispatch, useAppSelector } from "./store";
import ScreenLiveness from './js/components/screens/ScreenLiveness';
import ScreenCardDetect from "./js/components/screens/ScreenCardDetect";
import { ScreenCameraError } from './js/components/screens/ScreenCameraError';
import { EStateActions, IPath, setStateStore } from "./reducers/state";
import { ScreenInfoInit } from "./js/components/screens/ScreenInfoInit";
import { AnimatePresence } from "framer-motion";
import Animation from "./js/components/global/Animation";
import { ScreenInvalidVerification } from "./js/components/screens/ScreenInvalidVerification";
import { ScreenSelectCard } from "./js/components/screens/ScreenSelectCard";
import { ScreenMobileSelection } from "./js/components/screens/ScreenMobileSelection";
import { ScreenMobile } from "./js/components/screens/ScreenMobile";
import { send } from "@giantmachines/redux-websocket";
import { ScreenSocketError } from "./js/components/screens/ScreenSocketError";
import { ScreenCanceled } from "./js/components/screens/ScreenCanceled";
import { CancelListener } from "./js/components/functional/CancelListener";

interface IProps {
    debug: boolean,
    submitErrorMessage: (error: ErrorType) => void
}

export const MainRouter:React.FC<IProps> = ({
    debug,
    submitErrorMessage
}) => {
    const dispatch = useAppDispatch()
    
    const videoRef = useRef<HTMLVideoElement>(null)
    
    const currentVerification = useAppSelector(store => store.stateStore.currentVerification)
    const cameraError = useAppSelector(store => store.systemStore.cameraError)
    const selectedCard = useAppSelector(store => store.stateStore.selectedCard)
    const navigation = useAppSelector(store => store.stateStore.navigation)
    const currentNavId = useAppSelector(store => store.stateStore.currentNavId)
    const isMobile = useAppSelector(store => store.stateStore.isMobileVerification)
    const connected = useAppSelector(store => store.wsStore.connected)
    const isError = useAppSelector(store => store.wsStore.isError)
    const initialized = useAppSelector(store => store.wsStore.initialized)

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        })
    }, [currentNavId])

    useEffect(() => {
        let newNavigation: IPath[] = []
        let id = -5

        newNavigation.push({
            id: id++,
            screen: EScreen.mobile
        })

        newNavigation.push({
            id: id++,
            screen: EScreen.socketError
        })

        newNavigation.push({
            id: id++,
            screen: EScreen.canceledDesktop
        })

        newNavigation.push({
            id: id++,
            screen: EScreen.cameraError
        })
        
        newNavigation.push({
            id: id++,
            screen: EScreen.manual
        })

        if(currentVerification) {
            if(!isMobile) {
                newNavigation.push({
                    id: id++,
                    screen: EScreen.start
                })
            }
    
            newNavigation.push({
                id: id++,
                screen: EScreen.cardSelect
            })

            newNavigation.push({
                id: id++,
                screen: EScreen.selectMobileDesktop
            })

            let useLiveness = false

            if(selectedCard) {
                selectedCard.sides.forEach(s => {
                    newNavigation.push({
                        id: id++,
                        screen: EScreen.cardDetect,
                        sideType: s.type
                    })

                    useLiveness = s.serverSettings.hasFace?true:useLiveness
                })

                if(useLiveness && selectedCard.liveness.allow) {
                    newNavigation.push({
                        id: id++,
                        screen: EScreen.liveness
                    })
                }

                newNavigation.push({
                    id: id++,
                    screen: EScreen.confirm
                })
            }
        } else {
            newNavigation.push({
                id: id++,
                screen: EScreen.missingVerification
            })
        }

        dispatch(setStateStore(EStateActions.SET_NAVIGATION, newNavigation))
    }, [currentVerification, isMobile, dispatch, selectedCard])

    useEffect(() => {
        if(connected && initialized) {
            let sendWS: IMessageDetectStart = {
                type: EMessageType.detectStart,
                variation: isMobile?'qr':'local'
            }

            dispatch(send(sendWS))
        }
    }, [dispatch, isMobile, connected, initialized])

    useEffect(() => {
        const unloadCallback = () => {
            let sendWS: IMessageCurrentState = {
                type: EMessageType.currentState,
                state: isMobile?EState.canceled:EState.canceledFromDesktop,
                data: null
            }

            dispatch(send(sendWS))
            return "";
        };
      
        window.addEventListener("beforeunload", unloadCallback);

        return () => window.removeEventListener("beforeunload", unloadCallback);
      }, [dispatch, isMobile, connected, initialized]);

    let finalPath: IPath = {
        id: 0,
        screen: isMobile?EScreen.cardSelect:EScreen.start
    }

    let foundCurrentPath = navigation.find(n => n.id === currentNavId)
    let finalCameraError = navigation.find(n => n.screen === EScreen.cameraError)
    let finalSocketError = navigation.find(n => n.screen === EScreen.socketError)

    if(finalCameraError && cameraError) {
        finalPath = finalCameraError
    } else if (foundCurrentPath) {
        finalPath = foundCurrentPath
    }

    if(isError && finalSocketError) {
        finalPath = finalSocketError
    }

    let finalObject: React.ReactNode = null

    switch(finalPath.screen) {
        case EScreen.missingVerification:
            finalObject = (
                <ScreenInvalidVerification/>
            )
            break
        case EScreen.selectMobileDesktop:
            finalObject = (
                <ScreenMobileSelection videoRef={videoRef}/>
            )
            break
        case EScreen.mobile:
            finalObject = (
                <ScreenMobile/>
            )
            break
        case EScreen.cardSelect:
            finalObject = (
                <ScreenSelectCard/>
            )
            break
        case EScreen.cameraError:
            finalObject = (
                <ScreenCameraError/>
            )
            break
        case EScreen.start:
            finalObject = (
                <ScreenInfoInit/>
            )
            break
        case EScreen.socketError:
            finalObject = (
                <ScreenSocketError/>
            )
            break
        case EScreen.canceledDesktop:
            finalObject = (
                <ScreenCanceled/>
            )
            break
        case EScreen.cardDetect:
            if(finalPath.sideType) {
                finalObject = (
                    <ScreenCardDetect
                        debug={debug}
                        key={finalPath.sideType}
                        sendErrorMessage={submitErrorMessage}
                        videoRef={videoRef}
                        sideType={finalPath.sideType}
                        hideBack={finalPath.sideType === 'front' && isMobile}
                    />
                )
            } else {
                finalObject = null
            }
            break
        case EScreen.liveness:
            finalObject = (
                <ScreenLiveness 
                    debug={debug}
                    sendErrorMessage={submitErrorMessage}
                    videoRef={videoRef}
                />
            )
            break
        case EScreen.manual:
            finalObject = (
                <ScreenForm isManual={true}/>
            )
            break
        case EScreen.confirm:
            finalObject = (
                <ScreenForm isManual={false}/>
            )
            break
    }

    return (
        <>
            <CancelListener/>
            <AnimatePresence mode="wait">
                <Animation type="fade" key={currentNavId} duration={.5} className="main" objType="main">
                    {finalObject}
                </Animation>
            </AnimatePresence>
        </>
    )
}
