import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/index';
import { EMessageType, EState, EVerificationJournalMessage, ErrorType, IMessageCurrentState } from '../../../types';
import { EWSActions, setWsStore } from '../../../reducers/ws';
import { EStateActions, setStateStore } from '../../../reducers/state';
import ScreenLivenessDetect from './ScreenLivenessDetect';
import uploadClientFile from '../../../reducers/api/uploadClientFile';
import MainNavigation from '../global/MainNavigation';
import { addVerificationJournalData } from '../../../reducers/api/addVerificationJournalData';
import { send } from '@giantmachines/redux-websocket';

interface IProps {
    videoRef: React.RefObject<HTMLVideoElement>,
    debug: boolean,
    sendErrorMessage: (error: ErrorType) => void
}

const ScreenLiveness: React.FC<IProps> = ({
    videoRef,
    debug,
    sendErrorMessage
}) => {
    const dispatch = useAppDispatch()

    const verificationToken = useAppSelector(store => store.stateStore.verificationToken)
        
    const [loading, setLoading] = useState(false)

    useEffect(() => {        
        addVerificationJournalData(
            verificationToken,
            EVerificationJournalMessage.livenessStart,
            {}
        )

        let sendWS: IMessageCurrentState = {
            type: EMessageType.currentState,
            state: EState.face,
            data: null
        }

        dispatch(send(sendWS))
    }, [verificationToken, dispatch])

    return (
        <>
            <MainNavigation/>
            <ScreenLivenessDetect
                debug={debug}
                loading={loading}
                goBack={() => {
                    dispatch(setStateStore(EStateActions.GO_PREV, null))
                }}
                onValidated={(image) => {
                    if(image) {
                        
                        setLoading(true)

                        uploadClientFile(
                            image.image,
                            true,
                            'jpg',
                            image.box
                        ).then(res => {
                            if(res) {
                                let sendWS: IMessageCurrentState = {
                                    type: EMessageType.currentState,
                                    state: EState.faceSuccess,
                                    data: null
                                }
                        
                                dispatch(send(sendWS))
                                
                                dispatch(setWsStore(EWSActions.CLEAN_WS, null))
                                
                                dispatch(setStateStore(EStateActions.SET_FACE_FILENAME, res))
                                dispatch(setStateStore(EStateActions.SET_SIMILARITY, image.stats?image.stats.similarity:null))
                                addVerificationJournalData(
                                    verificationToken,
                                    EVerificationJournalMessage.livenessFinished,
                                    {
                                    },
                                    {
                                        face: res
                                    }
                                )
                                
                            } else {
                                dispatch(setStateStore(EStateActions.SET_UPLOADED_FILENAME, null))
                            }
                            
                            dispatch(setStateStore(EStateActions.GO_NEXT, null))
                        })
                    }
                }}
                sendErrorMessage={sendErrorMessage}
                videoRef={videoRef}
            />
        </>
    )
}

export default ScreenLiveness
