import { useAppSelector } from '../../../store';
import { motion } from 'framer-motion';
import { Trans } from '@lingui/macro';
import { useEffect, useRef, useState } from 'react';
import QRCodeStyling from "qr-code-styling";
import ButtonBack from '../functional/ButtonBack';
import { useDispatch } from 'react-redux';
import { EStateActions, setStateStore } from '../../../reducers/state';
import { MobileHistory } from '../functional/MobileHistory';
import { addVerificationJournalData } from '../../../reducers/api/addVerificationJournalData';
import { EMessageType, EState, EVerificationJournalMessage, IMessageCurrentState } from '../../../types';
import { send } from '@giantmachines/redux-websocket';

interface IProps {
}

export const ScreenMobile: React.FC<IProps> = ({
}) => {
    const ref = useRef<HTMLDivElement>(null)
    const dispatch = useDispatch()

    const verificationId = useAppSelector(store => store.stateStore.verificationToken)
    const verification = useAppSelector(store => store.stateStore.currentVerification)

    const [qrCodeInstance, setQrCodeInstance] = useState<QRCodeStyling | null>(null)
    const [url, setUrl] = useState<string|null>(null)

    useEffect(() => {
        if(qrCodeInstance && ref.current) {
            qrCodeInstance.append(ref.current);
        }
    }, [qrCodeInstance])
    
    useEffect(() => {
        addVerificationJournalData(
            verificationId,
            EVerificationJournalMessage.mobileDetectionStarted,
            {}
        )
    }, [verificationId])

    useEffect(() => {
        if(qrCodeInstance && url) {
            qrCodeInstance.update({
                data: url
            })
        }
    }, [qrCodeInstance, url])

    useEffect(() => {
        setUrl(window.location.origin + '?verification=' + verificationId + '&phone=true')
    }, [verificationId])

    useEffect(() => {
        if(verification) {
            let color = '#301B60'
            
            const qrCode = new QRCodeStyling({
                width: 500,
                height: 500,
                dotsOptions: {
                    color: color,
                    type: "rounded"
                },
                cornersSquareOptions: {
                    type: 'extra-rounded'
                },
                cornersDotOptions: {
                    type: 'dot'
                },
                backgroundOptions: {
                    color: 'transparent'
                }
            })
            
            setQrCodeInstance(qrCode)
        }
    }, [verification])

    return (
        <>
            <div className="block_center_vert wrapper pdt-50 pdb-50">
                <h2 className="text_center title_h1 mgt-15">
                    <Trans>Overenie prostredníctvom mobilu</Trans>
                </h2>
                
                <form action="/" method="post">
                    <div className="box_process_type mgt-45">
                        <motion.div 
                            className="item_process" 
                            initial={{ 
                                opacity: 0,
                                x: -10
                            }}
                            animate={{ 
                                opacity: 1, 
                                x: 0,
                                transition: {
                                    duration: .5
                                }
                            }}
                        >
                            <div className="wrapper-history">
                                <div className="icon2 mid canvas" ref={ref}/>
                            
                                <MobileHistory onSuccessFinish={data => {
                                    dispatch(setStateStore(EStateActions.SET_MOBILE_RESULT, data))
                                }}/>
                            </div>

                            <div className="item_process_title"><Trans>Naskenujte QR kód a pokračujte podľa inštrukcií v zariadení.</Trans></div>
                        </motion.div>
                    </div>
                </form>
                
            </div>
            <div className="main_bottom">
                <div className="box_buttons">
                    <div className="box_buttons_left">
                        <ButtonBack 
                            onClick={() => {
                                let sendWS: IMessageCurrentState = {
                                    type: EMessageType.currentState,
                                    state: EState.canceledFromDesktop,
                                    data: null
                                }
                    
                                dispatch(send(sendWS))

                                dispatch(setStateStore(EStateActions.GO_HOME, null))
                            }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
