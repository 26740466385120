import React, { useState, useEffect, useLayoutEffect, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import { useAppDispatch, useAppSelector } from "../../../store/index";
import {
  IImageInfoFull,
  IImageInfo,
  IMessageCard,
  EMessageType,
  IDimensions,
  ErrorType,
  ECardSideType,
} from "../../../types";
import { send } from "@giantmachines/redux-websocket";
import moment from "moment";
import { EWSActions, setWsStore } from "../../../reducers/ws";
import config from "../../../config";
import { LiveVideoElement } from "../functional/LiveVideoElement";
import { ConfirmPhoto } from "../functional/ConfirmPhoto";
import { drawPhoto, translateText } from "../../helpers/utils";
import { logDebug, logInfo, logWarning } from "../../helpers/logger";
import { useMessage } from "../../hooks/useMessage";
import MainNavigation from "../global/MainNavigation";
import { EStateActions, setStateStore } from "../../../reducers/state";
import { useLingui } from "@lingui/react";

const delay = config.delay.id_photo;

interface IProps {
  videoRef: React.RefObject<HTMLVideoElement>;
  sideType: ECardSideType;
  debug: boolean;
  loading: boolean;
  onCapture: (
    image: IImageInfoFull,
    descriptors: Float32Array[],
    fallback: () => void,
    fallBackSide: () => void,
    onError: () => void
  ) => void;
  goBack: () => void;
  hideBack?: boolean;
  sendErrorMessage: (error: ErrorType) => void;
}

enum ECollecting {
  nothing,
  collecting,
  collected,
}

const ScreenCapture: React.FC<IProps> = ({
  videoRef,
  sideType,
  debug,
  loading,
  goBack,
  hideBack,
  onCapture,
  sendErrorMessage,
}) => {
  const dispatch = useAppDispatch();
  const lingui = useLingui();

  const currentSides = useAppSelector(
    (store) => store.stateStore.cardSidesData
  );
  const message = useAppSelector((store) => store.wsStore.message);
  const videoIsPlaying = useAppSelector(
    (store) => store.systemStore.videoIsPlaying
  );
  const selectedCard = useAppSelector((store) => store.stateStore.selectedCard);

  const [images, setImages] = useState<IImageInfo[]>([]);
  const [areImagesValid, setAreImagesValid] = useState(false);
  const [bestImage, setBestImage] = useState<IImageInfoFull | null>(null);
  const [collecting, setCollecting] = useState(ECollecting.nothing);
  const [faceDescriptors, setFaceDescriptors] = useState<Float32Array[]>([]);
  const [badCard, setBadCard] = useState(false);
  const [badSide, setBadSide] = useState(false);

  const selectedSideConfig = useMemo(
    () => selectedCard?.sides.find((s) => s.type === sideType),
    [selectedCard, sideType]
  );
  const selectedSide = useMemo(
    () => currentSides.find((s) => s.sideType === sideType),
    [currentSides, sideType]
  );
  const needFace = useMemo(
    () => (selectedSideConfig?.serverSettings.hasFace ? true : false),
    [selectedSideConfig]
  );

  const { showMessage, frameClass, iconType } = useMessage(
    message,
    needFace,
    areImagesValid
  );

  useEffect(() => {
    if (
      selectedCard &&
      videoIsPlaying &&
      collecting === ECollecting.collecting &&
      areImagesValid
    ) {
      logDebug("Creating new collecting interval");

      let timeout = setTimeout(() => {
        logInfo(sideType + " images collecting finished ....");

        setCollecting(ECollecting.collected);
      }, selectedCard.secondsToConfirm * 1000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [collecting, selectedCard, sideType, videoIsPlaying, areImagesValid]);

  useEffect(() => {
    console.log("calling dispatch effect");
    setImages([]);
    setBestImage(null);
    setCollecting(ECollecting.nothing);
    setAreImagesValid(false);
    dispatch(setWsStore(EWSActions.MESSAGE, { message: undefined }));
    dispatch(setWsStore(EWSActions.SHOW_MESSAGE, true));
    dispatch(
      setStateStore(EStateActions.SET_UPLOADED_FILENAME, {
        sideType: sideType,
        filename: null,
      })
    );
  }, [dispatch, sideType]);

  useEffect(() => {
    //console.log('calling get best image effect')
    //console.log(collected, pairedImages.length, imageUploaded)

    if (
      collecting === ECollecting.collected &&
      images.length &&
      selectedSide &&
      selectedSide.filename === null &&
      areImagesValid
    ) {
      //console.log('inside get best image effect')

      let sortedImages = images.filter(
        (img) => !!img.stats
      ) as IImageInfoFull[];

      if (needFace) {
        sortedImages = sortedImages.filter((si) => si.stats.descriptor);

        if (!sortedImages.length) {
          logWarning(
            "there are no valid face descriptors, waiting new descirptors .."
          );
          return;
        }
      }

      if (!sortedImages.length) {
        logWarning("there are no valid images");
        return;
      }

      sortedImages
        .sort((a, b) => b.stats.sharpness - a.stats.sharpness)
        .map((img, i) => ({ ...img, score: i }))
        .sort((a, b) => a.stats.card_in - b.stats.card_in)
        .map((img, i) => ({ ...img, score: img.score + i }))
        .sort((a, b) => a.score - b.score);

      let result = sortedImages[0];

      logInfo(sideType + " best image stats: ", result.stats);
      setBestImage(result);

      const extractedFD = sortedImages
        .filter((s) => s.stats.descriptor)
        .map((s) => s.stats.descriptor?.descriptor);

      setFaceDescriptors(extractedFD);
      dispatch(setWsStore(EWSActions.SHOW_MESSAGE, false));
    }
  }, [
    dispatch,
    images,
    sideType,
    collecting,
    areImagesValid,
    needFace,
    selectedSide,
  ]);

  // console.log(images.map(i => i.id), images.filter(i => i.stats?.descriptor).map(i => i.id))
  useLayoutEffect(() => {
    //console.log('calling message effect')

    if (selectedCard && message && message.type === "analyze") {
      setImages((oldImages) => {
        let finalImages = oldImages.filter((old) =>
          moment()
            .subtract(selectedCard.secondsToConfirm, "seconds")
            .isSameOrBefore(moment(old.time))
        );

        finalImages = finalImages.map((oldI) => {
          if (oldI.id === message.imageId) {
            if (message.data.results.correct) {
              setCollecting((c) =>
                c === ECollecting.collected ? c : ECollecting.collecting
              );

              let finalI: IImageInfoFull = oldI as any;
              finalI.stats = message.data;

              return finalI;
            } else {
              setCollecting(ECollecting.nothing);
            }

            return oldI;
          } else {
            return oldI;
          }
        });

        if (needFace) {
          if (
            finalImages.filter((i) => i.stats?.descriptor?.descriptor).length
          ) {
            setAreImagesValid(true);
          } else {
            setAreImagesValid(false);
          }
        } else {
          setAreImagesValid(true);
        }
        return finalImages;
      });
    }
  }, [message, needFace, selectedCard]);

  // get current image from video stream
  useEffect(() => {
    let running = false;
    let inputCanvas = document.createElement("canvas");
    const videoEl = videoRef.current;

    const updateCanvas = async () => {
      if (
        running ||
        (collecting === ECollecting.collected && !areImagesValid)
      ) {
        return;
      }
      //console.log('calling update canvas ...')
      running = true;

      if (
        selectedSideConfig &&
        videoEl &&
        inputCanvas &&
        videoIsPlaying &&
        videoEl.videoWidth > 0 &&
        selectedCard !== null
      ) {
        if (videoEl.paused || videoEl.ended) {
          running = false;
          return;
        }

        let box = drawPhoto(videoEl, inputCanvas, false);
        //console.log('returned box', box)

        if (box) {
          let dimensions: IDimensions = {
            ...box,
            brightnessThreshold: selectedSideConfig.brightness,
            sharpnessThreshold: selectedSideConfig.sharpness,
            cardInThreshold: selectedSideConfig.serverSettings.cardIn,
          };

          let id = uuidv4();

          let sendWS: IMessageCard = {
            image: inputCanvas.toDataURL("image/jpeg", 0.6),
            imageId: id,
            debug: debug,
            dimensions: dimensions,
            type: EMessageType.card,
            detectFace: needFace,
          };

          //console.log('sending photo via websockets')
          dispatch(send(sendWS));

          let newImage: IImageInfo = {
            score: 0,
            image: inputCanvas.toDataURL("image/jpeg", 0.9),
            time: moment().toISOString(),
            id: id,
          };

          setImages((oldI) => [...oldI, newImage]);
        } else {
          logWarning("The returned box is null");
        }
      }
      running = false;
    };

    let interval = setInterval(updateCanvas, delay);
    return () => {
      clearInterval(interval);
      inputCanvas.remove();
    };
  }, [
    videoRef,
    videoIsPlaying,
    dispatch,
    debug,
    areImagesValid,
    sideType,
    collecting,
    needFace,
    selectedSideConfig,
    selectedCard,
  ]);

  if (!selectedSideConfig) {
    return null;
  }

  let content: React.ReactNode = null;

  if (bestImage && collecting === ECollecting.collected) {
    content = (
      <ConfirmPhoto
        image={bestImage.image}
        key={sideType}
        onAccept={() => {
          onCapture(
            bestImage,
            faceDescriptors,
            () => {
              setBadCard(true);
              setBadSide(false);
            },
            () => {
              setBadSide(true);
              setBadCard(false);
            },
            () => {
              setBadCard(false);
              setBadSide(false);
              setImages([]);
              setBestImage(null);
              setCollecting(ECollecting.nothing);
              setAreImagesValid(false);
            }
          );
        }}
        onCancel={() => {
          setBadCard(false);
          setBadSide(false);
          setImages([]);
          setBestImage(null);
          setCollecting(ECollecting.nothing);
          setAreImagesValid(false);
        }}
        badCard={badCard}
        badSide={badSide}
        loading={loading}
        imgAlt={translateText(
          selectedSideConfig.labels.title,
          lingui.i18n.locale
        )}
        aspectHeight={
          selectedSide
            ? selectedSideConfig.serverSettings.aspectHeight
            : undefined
        }
        aspectWidth={
          selectedSide
            ? selectedSideConfig.serverSettings.aspectWidth
            : undefined
        }
      />
    );
  } else {
    content = (
      <LiveVideoElement
        goBack={goBack}
        hideBack={hideBack}
        label={translateText(
          selectedSideConfig.labels.loadSide,
          lingui.i18n.locale
        )}
        videoRef={videoRef}
        isFace={false}
        debug={debug}
        key={sideType}
        message={showMessage}
        iconType={iconType}
        frameClass={frameClass}
        sendErrorMessage={sendErrorMessage}
        aspectHeight={
          selectedSide
            ? selectedSideConfig.serverSettings.aspectHeight
            : undefined
        }
        aspectWidth={
          selectedSide
            ? selectedSideConfig.serverSettings.aspectWidth
            : undefined
        }
      />
    );
  }

  return (
    <>
      <MainNavigation />
      {content}
    </>
  );
};

export default ScreenCapture;
