import React from 'react'
import { Trans } from '@lingui/macro';
import { motion } from 'framer-motion';

interface IProps {
}

export const ScreenCanceled: React.FC<IProps> = () => {
    return (
        <div className="block_center_vert wrapper pdt-50 pdb-50">
            <h2 className="text_center title_h1 mgt-15">
                <Trans>Overenie bolo zrušené</Trans>
            </h2>
            
            <form action="/" method="post">
                <div className="box_process box_process_type mgt-45">
                    <motion.div 
                        className="item_process" 
                        initial={{ 
                            opacity: 0,
                            x: -10
                        }}
                        animate={{ 
                            opacity: 1, 
                            x: 0,
                            transition: {
                                duration: 1,
                                delay: .5
                            }
                        }}
                    >
                        <div className="icon2 mid">
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M8.6 1c1.6.1 3.1.9 4.2 2 1.3 1.4 2 3.1 2 5.1 0 1.6-.6 3.1-1.6 4.4-1 1.2-2.4 2.1-4 2.4-1.6.3-3.2.1-4.6-.7-1.4-.8-2.5-2-3.1-3.5C.9 9.2.8 7.5 1.3 6c.5-1.6 1.4-2.9 2.8-3.8C5.4 1.3 7 .9 8.6 1zm.5 12.9c1.3-.3 2.5-1 3.4-2.1.8-1.1 1.3-2.4 1.2-3.8 0-1.6-.6-3.2-1.7-4.3-1-1-2.2-1.6-3.6-1.7-1.3-.1-2.7.2-3.8 1-1.1.8-1.9 1.9-2.3 3.3-.4 1.3-.4 2.7.2 4 .6 1.3 1.5 2.3 2.7 3 1.2.7 2.6.9 3.9.6zM7.9 7.5L10.3 5l.7.7-2.4 2.5 2.4 2.5-.7.7-2.4-2.5-2.4 2.5-.7-.7 2.4-2.5-2.4-2.5.7-.7 2.4 2.5z"></path></svg>
                        </div>

                        <div className="item_process_title"><Trans>Pokračujte ďalej na počítači.</Trans></div>
                    </motion.div>
                </div>
            </form>
            
        </div>
    )
}