import * as React from 'react';
import { ECardType } from './config';

export interface INotification {
    text: string | React.ReactNode,
    type: 'success' | 'info' |  'warning' | 'error',
    showBell?: boolean
}

export interface IBox {
    top: number,
    left: number,
    width: number,
    height: number
}

export interface INotificationFull  extends INotification {
    id: number,
    validFrom: Date,
    goingToHide: boolean,
    goingToShow: boolean
}

export enum EImageAnalyse {
    correct = 'correct',
    to_left = 'to_left',
    to_right = 'to_right',
    to_bottom = 'to_bottom',
    to_top = 'to_top',
    small = 'small',
    big = 'big',
    empty = 'empty',
    low_s = 'low_s',
    low_b = 'low_b',
    missing_face = 'missing_face',
    grayscale = 'grayscale',
}

export interface IImageAnalyseResult {
    correct: boolean,
    to_left: boolean,
    to_right: boolean,
    to_bottom: boolean,
    to_top: boolean,
    small: boolean,
    big: boolean,
    empty: boolean,
    low_s: boolean,
    low_b: boolean,
    grayscale: boolean,
}

export enum EMessageType {
    card = 'card',
    face = 'face',
    liveness = 'liveness',
    detectStart = 'detectStart',
    verificationStart = 'verificationStart',
    currentState = 'currentState'
}

export interface IFaceDimensions {
    top: number,
    left: number,
    width: number,
    height: number,
    totalWidth: number,
    totalHeight: number
}

export interface IDimensions extends IFaceDimensions{
    brightnessThreshold: number,
    sharpnessThreshold: number,
    cardInThreshold: number
}

export interface IMessageCard {
    type: EMessageType.card,
    image: string,
    imageId: string,
    debug: boolean,
    dimensions: IDimensions,
    detectFace: boolean
}

export interface IMessageFace {
    type: EMessageType.face,
    image: string,
    imageId: string,
    descriptors: Float32Array[],
    debug: boolean,
    thresholdSimilarity: number,
    thresholdArea: number,
    dimensions: IFaceDimensions
}

export type EmotionType = 'neutral'|'angry'|'disgusted'|'fearful'|'happy'|'neutral'|'sad'|'surprised'

export interface IMessageLiveness {
    type: EMessageType.liveness,
    image: string,
    imageId: string,
    descriptors: Float32Array[],
    debug: boolean,
    thresholdSimilarity: number,
    thresholdEmotion: number,
    emotionCheck: EmotionType,
    dimensions: {
        width: number,
        height: number
    }
}

export interface IMessageDetectStart {
    type: EMessageType.detectStart,
    variation: 'qr'|'local'
}

export enum EState {
    start = 'start',
    detectCard = 'detectCard',
    detectCardSuccess = 'detectCardSuccess',
    face = 'face',
    faceSuccess = 'faceSuccess',
    confirm = 'confirm',
    canceled = 'canceled',
    canceledFromDesktop = 'canceledFromDesktop'
}

export interface IMessageCurrentState {
    type: EMessageType.currentState,
    state: EState
    data: TWSMessageData
}

export interface IMessageVerificationStart {
    type: EMessageType.verificationStart,
    verificationToken: string,
}

export interface IResponseAnalyzeData {
    card_total: number
    card_in: number
    card_out: number
    left: number
    right: number
    top: number
    bottom: number
    sharpness: number
    brightness: number,
    results: IImageAnalyseResult,
    descriptor?: {
        descriptor: any
    },
    image?: string
}

export enum EFaceResponse {
    correct = 'correct',
    too_big = 'too_big',
    too_small = 'too_small',
    out_area = 'out_area',
    not_valid = 'not_valid'
}

interface IResponseFaceData {
    dist: number,
    face: any,
    inPosition: boolean,
    areaRatio: number,
    response: EFaceResponse,
    similarity: number
}

export interface IResponseAnalyze {
    imageId: string,
    type: 'analyze',
    data: IResponseAnalyzeData
}

export interface IResponseBusy {
    imageId: string,
    type: 'srv_busy'
}

export interface IResponseFace {
    imageId: string,
    type: 'face',
    data: IResponseFaceData
}

export interface IResponseDetectStart {
    type: 'detectStart',
    variation: 'qr'|'local'
}

export interface IResponseCurrentState {
    type: 'currentState',
    state: EState,
    data: any
}

export type IWSMessage = IResponseAnalyze | IResponseFace | IResponseBusy | IResponseLiveness | IResponseDetectStart | IResponseCurrentState | null

export interface IImageInfo {
    score: number,
    image: string,
    id: string,
    time: string,
    stats?: IResponseAnalyzeData
}

export interface IImageInfoFull extends IImageInfo {
    stats: IResponseAnalyzeData
}

export interface IImageFaceInfo {
    image: string,
    id: string,
    score: number,
    stats?: IResponseFaceData,
    time: string,
    box: {
        left: number,
        top: number,
        width: number,
        height: number
    }
}

export enum ELivenessResponse {
    not_correct = 'not_correct',
    emotion_valid = 'emotion_valid',
    emotion_invalid = 'emotion_invalid'
}

export interface IResponseLivenessData {
    dist: number,
    angle: number,
    emotion: number,
    emotionType: EmotionType,
    response: ELivenessResponse,
    detectedEmotion: EmotionType,
    detectedEmotionValue: number,
    res: any
}

export interface IResponseLiveness {
    imageId: string,
    type: 'liveness',
    data: IResponseLivenessData
}

export enum EEmotion {
    neutral = 'neutral',
    surprised = 'surprised',
    happy = 'happy'
}

export enum EVerificationResult {
    VALID = 'VALID',
    INVALID = 'INVALID',
    ERROR = 'ERROR',
}

export interface IVerificationResponse {
    status: 'success' | 'failed',
    message: string,
    result: EVerificationResult,
    similarity: number,
}

export interface IFile extends File {
    preview?: string
}

export enum ECameraError {
    CameraNotSupported,
    CameraOccupied,
    PermissionDenied,
    Overconstrained,
    UnknownError
}

export enum ECardSideType {
    front = 'front',
    back = 'back'
}

export interface IText {
    size: 'full'|'half'|'textarea',
    type: 'string'|'number'|'date',
    key: string,
    title: ITranslatable
}

export interface ITranslatable {
    sk: string,
    en: string
}

export interface ICardSideServerSettings {
    cardIn: number,
    aspectWidth: number,
    aspectHeight: number,
    hasFace: boolean
}

export interface ICardSideConfig{
    sharpness: number,
    brightness: number,
    type: ECardSideType,
    labels: {
        title: ITranslatable,
        loadSide: ITranslatable
    },
    serverSettings: ICardSideServerSettings
}

export interface ICardConfigData {
    name: ECardType,
    colorIcon: string,
    colorBackground: string,
    labels: ITranslatable,
    country: string,
    liveness: ILivenessConfig,
    allowOcr: boolean,
    allowManual: boolean,
    secondsToConfirm: number,
    readParts: string[],
    sides: ICardSideConfig[]
}

export interface ILivenessConfig {
    allow: boolean,
    minimumEmotionFrames: number,
    minimumSeconds: number,
    thresholdSimilarity: number,
    thresholdEmotion: number,
    thresholdArea: number
}

export enum EVerificationStatus {
    INITIALIZED = 'INITIALIZED',
    PROCESSING = 'PROCESSING',
    PENDING = 'PENDING',
    FINISHED = 'FINISHED'
}

export interface IVerificationConfig {
    status: EVerificationStatus,
    lastAction: string,
    verificationToken: string,
    expoirationDate: string,
    configurations: {
        process: {
            [key: string]: ICardConfigData
        },
        visual: {
            visualSettings: {
                languages: string[]
                primaryColor: string|null,
                secondaryColor: string|null,
                backgroundColor: string|null,
                introductionText: string|null,
                logoUrl: string|null,
            }
        }
    },
    language: string,
    files: string[]
}

export interface IVerificationResultData {
    imageFileNames: string[],
    readedData: IOCRResponseTextBasic[],
    faces: string[],
    similarity: number,
    manual: boolean
}

export enum EResultType {
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR'
}

export enum ErrorType {
    MISSING_CAMERA,
    CANCELED,
    PERMISSION_DENIED,
    CAMERA_ERROR,
    VERIFICATION_INIT_ERROR
}

export interface IVerificationResult {
    result: EResultType,
    errorType: ErrorType|null,
    message: string,
    data: IVerificationResultData|null,
}

export enum EScreen {
    start, //0
    selectMobileDesktop, //1
    mobile, //2
    cameraError, //3
    socketError, //4
    canceledDesktop, //5
    cardSelect, //6
    cardDetect, //7
    liveness, //8
    manual, //9
    confirm, //10
    missingVerification, //11
}

export enum EVerifyType {
    none,
    verifying,
    verified
}

export interface IFileUpload {
    selectedFile: IFile|null,
    sideType: ECardSideType
}

export interface IOCRResponseTextBasic {
    key: string,
    value: {
        value: string,
        confidence: number
    }|null,
}

export interface IOCRResponseText extends IOCRResponseTextBasic{
    ui: IText
}

export enum EVerificationJournalMessage {
    verificationLoaded = 'verificationLoaded',
    verificationInit = 'verificationInit',
    selectCard = 'selectCard',
    snapStarted = 'snapStarted',
    snapFinished = 'snapFinished',
    badCard = 'badCard',
    badSide = 'badSide',
    snapProcessed = 'snapProcessed',
    livenessStart = 'livenessStart',
    livenessInitFinished = 'livenessInitFinished',
    livenessNeutralFinished = 'livenessNeutralFinished',
    livenessHappyFinished = 'livenessHappyFinished',
    livenessFinished = 'livenessFinished',
    manualStarted = 'manualStarted',
    manualFinished = 'manualFinished',
    previewResults = 'previewResults',
    verificationEnd = 'verificationEnd',
    cameraIssue = 'cameraIssue',
    mobileDetectionStarted = 'mobileDetectionStarted',
}

export interface IWSMessageDataDetectStart {
    side: ECardSideType,
    card: ICardConfigData|null
}

export interface IWSMessageDataDetectSuccess {
    side: ECardSideType
}

export interface IWSMessageDataFinish {
    readedTexts: IOCRResponseText[],
    cardSides: {
        sideType: ECardSideType;
        filename: string | null;
    }[],
    faceFileName: string | null
}

export type TWSMessageData = IWSMessageDataDetectStart | IWSMessageDataDetectSuccess | IWSMessageDataFinish | null