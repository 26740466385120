import React, { useState, useEffect } from 'react';
import { send } from '@giantmachines/redux-websocket';
import { v4 as uuidv4 } from 'uuid';
import { IMessageFace, EMessageType, IImageFaceInfo, EFaceResponse, IResponseLivenessData, EEmotion, ELivenessResponse, IMessageLiveness, ErrorType, EVerificationJournalMessage } from '../../../types';
import { drawPhoto } from '../../helpers/utils';
import moment from 'moment';
import { setWsStore, EWSActions } from '../../../reducers/ws';
import config from '../../../config';
import { logDebug } from '../../helpers/logger';
import { useMessage } from '../../hooks/useMessage';
import { useAppDispatch, useAppSelector } from '../../../store/index';
import { LiveVideoElement } from '../functional/LiveVideoElement';
import { t } from '@lingui/macro';
import { addVerificationJournalData } from '../../../reducers/api/addVerificationJournalData';

const delay = config.delay.faceTracking

interface IProps {
    videoRef: React.RefObject<HTMLVideoElement>,
    debug: boolean,
    goBack: () => void,
    onValidated: (bestImage: IImageFaceInfo|null) => void,
    sendErrorMessage: (error: ErrorType) => void,
    loading: boolean
}

enum ECollecting {
    nothing,
    collecting,
    collected
}

const ScreenLivenessDetect: React.FC<IProps> = ({
    videoRef,
    debug,
    goBack,
    onValidated,
    sendErrorMessage,
    loading
}) => {
    const dispatch = useAppDispatch()

    const selectedCard = useAppSelector(store => store.stateStore.selectedCard)
    const faceDescriptors = useAppSelector(store => store.stateStore.faceDescriptors)
    const message = useAppSelector(store => store.wsStore.message)
    const videoIsPlaying = useAppSelector(store => store.systemStore.videoIsPlaying)
    const verificationToken = useAppSelector(store => store.stateStore.verificationToken)
    
    const [currentEmotion, setCurrentEmotion] = useState<EEmotion>(EEmotion.neutral)
    const [bestImage, setBestImage] = useState<IImageFaceInfo|null>(null)
    const [, setImages] = useState<IImageFaceInfo[]>([])
    const [collecting, setCollecting ] = useState(ECollecting.nothing)
    const [pairedImages, setPairedImages] = useState<IImageFaceInfo[]>([])
    const [messages, setMessages] = useState<IResponseLivenessData[]>([])
    const [emotionsCheck, setEmotionsCheck] = useState<boolean>(false)

    const { showMessage, frameClass, iconType } = useMessage(message)

    useEffect(() => {       
        if(emotionsCheck) {
            addVerificationJournalData(
                verificationToken,
                EVerificationJournalMessage.livenessInitFinished,
                {}
            )
        }
    }, [verificationToken, emotionsCheck])

    useEffect(() => {
        if (emotionsCheck && message && (message.type === EMessageType.liveness)) {
            if (message.data.response === ELivenessResponse.emotion_valid && message.data.detectedEmotion === currentEmotion) {
                setMessages(ms => {
                    return [
                        ...ms.filter(ms => ms.response === ELivenessResponse.emotion_valid),
                        message.data
                    ]
                })
            } else {
                setMessages([])
            }
        }
    }, [emotionsCheck, message, currentEmotion])

    useEffect(() => {
        if (emotionsCheck && selectedCard && messages.length >= selectedCard.liveness.minimumEmotionFrames) {
            setCurrentEmotion(e => {
                if (e === EEmotion.neutral) {
                    logDebug('neutral emotion successfully validated')
                    addVerificationJournalData(
                        verificationToken,
                        EVerificationJournalMessage.livenessNeutralFinished,
                        {}
                    )
                    setMessages([])
                    return EEmotion.happy
                } else if (e === EEmotion.happy) {
                    logDebug('happy emotion successfully validated')

                    addVerificationJournalData(
                        verificationToken,
                        EVerificationJournalMessage.livenessHappyFinished,
                        {}
                    )
                    setMessages([])
                    onValidated(bestImage)
                }
                
                return e
            })
        }
    }, [emotionsCheck, verificationToken, messages, onValidated, selectedCard, currentEmotion, dispatch, bestImage])

    useEffect(() => {
        let timeout = setTimeout(() => {
            if (selectedCard && collecting === ECollecting.collecting) {
                logDebug('Face images collecting finished')
                setCollecting(ECollecting.collected)
                setEmotionsCheck(true)
            }
        }, (selectedCard?selectedCard.liveness.minimumSeconds:1)*1000)

        return () => {
            clearTimeout(timeout)
        }
    }, [collecting, selectedCard])

    useEffect(() => {
        dispatch(setWsStore(EWSActions.MESSAGE, {message: undefined}))
        dispatch(setWsStore(EWSActions.SHOW_MESSAGE, true))
    }, [dispatch])

    useEffect(() => {
        //console.log('paired iamges length: ', pairedImages.length)
        //console.log('collecting: ', collecting)

        if (pairedImages.length && (collecting === ECollecting.collected)) {
            let bestImage = pairedImages[0]

            pairedImages.forEach(pi => {
                if (
                    bestImage.stats &&
                    pi.stats &&
                    (bestImage.stats.dist < pi.stats.dist)
                ) {
                    bestImage = pi
                }
            })

            logDebug('Face best image stats: ', bestImage.stats)
            setBestImage(bestImage)
            dispatch(setWsStore(EWSActions.SHOW_MESSAGE, false))
        }
    }, [dispatch, pairedImages, collecting])

    useEffect(() => {

        if (selectedCard && !emotionsCheck && message && (message as any).imageId && collecting !== ECollecting.collected) {
            setImages(oldImages => {
                if( message.type === 'face') {
                    let oldI = oldImages.find(i => i.id === message.imageId)

                    if(oldI) {
                        setPairedImages(opI => {
                            if (oldI &&  message.type === 'face') {
                                if(message.data.face && message.data.response === EFaceResponse.correct) {
                                    setCollecting(ECollecting.collecting)
                                    return [
                                        ...opI,
                                        {
                                            ...oldI,
                                            stats: message.data
                                        }
                                    ].filter(old => moment().subtract(selectedCard.liveness.minimumSeconds, 'seconds').isSameOrBefore(moment(old.time)))
                                } else {
                                    setCollecting(ECollecting.nothing)
                                    return []
                                }
                            } else {
                                return opI
                            }
                        })
                    }
                }
                return oldImages
                    .filter(i => i.id !== (message as any).imageId)
                    .filter(old => moment().subtract(selectedCard.liveness.minimumSeconds, 'seconds').isSameOrBefore(moment(old.time)))
            })
        }
    }, [message, collecting, selectedCard, emotionsCheck])

    // get current image from video stream
    useEffect(() => {
        let running = false
        let inputCanvas = document.createElement('canvas')

        const updateCanvas = async () => {
            if (running || !selectedCard) {
                return
            }

            const videoEl = videoRef.current
            running = true

            if (videoEl && inputCanvas && videoIsPlaying && videoEl.videoWidth > 0) {

                let box = drawPhoto(videoEl, inputCanvas, true)
                let id = uuidv4()
                let photo = inputCanvas.toDataURL('image/jpeg', 0.6)

                let commonParams = {
                    image: photo,
                    imageId: id,
                    debug: debug,
                    descriptors: faceDescriptors,
                    thresholdSimilarity: selectedCard.liveness.thresholdSimilarity,
                }

                if (emotionsCheck) {
                    let dimensions = {
                        width: videoEl.videoWidth,
                        height: videoEl.videoHeight
                    }

                    let sendWS: IMessageLiveness = {
                        ...commonParams,
                        image: photo,
                        type: EMessageType.liveness,
                        thresholdEmotion: selectedCard.liveness.thresholdEmotion/100,
                        emotionCheck: currentEmotion,
                        dimensions: dimensions
                    }

                    dispatch(send(sendWS))
                } else {
                    let dimensions = box

                    if (dimensions) {
                        let sendWS: IMessageFace = {
                            ...commonParams,
                            thresholdArea: selectedCard.liveness.thresholdArea,
                            type: EMessageType.face,
                            dimensions: dimensions
                        }

                        dispatch(send(sendWS))

                        let newImage: IImageFaceInfo = {
                            image: inputCanvas.toDataURL('image/jpeg', 0.9),
                            time: moment().toISOString(),
                            id: id,
                            score: 0,
                            box: dimensions
                        }

                        setImages(oldI => [...oldI, newImage])
                    }
                }
            }
            running = false
        }

        let interval = setInterval(updateCanvas, delay)
        return () => {
            clearInterval(interval)
            inputCanvas.remove()
        }
        
    }, [videoRef, emotionsCheck, videoIsPlaying, debug, dispatch, faceDescriptors, currentEmotion, selectedCard])

    return (
        <>
            <LiveVideoElement
                goBack={goBack}
                loading={loading}
                label={t`Overenie totožnosti tváre`}
                videoRef={videoRef}
                iconType={iconType}
                isFace={true}
                debug={debug}
                message={showMessage}
                frameClass={frameClass}
                sendErrorMessage={sendErrorMessage}
            />
        </>
    );
}

export default ScreenLivenessDetect
