import { useAppDispatch, useAppSelector } from '../../../store';
import { motion } from 'framer-motion';
import { Trans } from '@lingui/macro';
import { useEffect, useState } from 'react';
import { checkCameraSupported } from '../../helpers/utils';
import { IconLoading } from '../icons/IconLoading';
import { useCamera } from '../../hooks/useCamera';
import { EStateActions, EVerificationType, setStateStore } from '../../../reducers/state';
import { useIsMobileDevice } from '../../hooks/useIsMobileDevice';

interface IProps {
    videoRef: React.RefObject<HTMLVideoElement>,
}

export const ScreenMobileSelection: React.FC<IProps> = ({
    videoRef
}) => {
    const dispatch = useAppDispatch()
    const [hasCamera, setHasCamera] = useState<boolean|undefined>(undefined)
    const [loadingCamera, setLoadingCamera] = useState(true)
    const [allowedDesktop, setAllowedDesktop] = useState(false)

    const videoOptions = useAppSelector(store => store.systemStore.videoOptions)
    const cameraData = useCamera(videoRef, videoOptions)
    const isMobile = useIsMobileDevice()

    useEffect(() => {
        setHasCamera(checkCameraSupported())
    }, [])

    useEffect(() => {
        const timeout = setTimeout(() => {
            if(hasCamera && cameraData.devices.length > 0 && !cameraData.initialising) {
                setAllowedDesktop(true)
            }

            setLoadingCamera(cameraData.initialising)
        }, 500)

        return () => {
            clearTimeout(timeout)
        }
    }, [cameraData, hasCamera])

    useEffect(() => {
        if(!allowedDesktop && !loadingCamera) {
            dispatch(setStateStore(EStateActions.GO_MOBILE, true))
            dispatch(setStateStore(EStateActions.SET_VERIFICATION_TYPE, EVerificationType.MOBILE))
        }
    }, [dispatch, allowedDesktop, loadingCamera])

    useEffect(() => {
        if(isMobile) {
            dispatch(setStateStore(EStateActions.GO_NEXT, true))
            dispatch(setStateStore(EStateActions.SET_VERIFICATION_TYPE, EVerificationType.DESKTOP))
        }
    }, [dispatch, isMobile])

    if(loadingCamera || !allowedDesktop) {
        return (
            <IconLoading show={true} />
        )
    }

    return (
        <div className="block_center_vert wrapper pdt-50 pdb-50">
            <h2 className="text_center title_h1 mgt-15">
                {allowedDesktop?<Trans>Spôsob overenia</Trans>:<Trans>Pokračujte na mobilnom zariadení</Trans>}
            </h2>
            
            <form action="/" method="post">
                <div className="box_process box_process_type mgt-45">
                    <motion.div 
                        className="item_process" 
                        initial={{ 
                            opacity: 0,
                            x: -10
                        }}
                        animate={{ 
                            opacity: 1, 
                            x: 0,
                            transition: {
                                duration: .5
                            }
                        }}
                    >
                        <div className="icon2 mid">
                            <svg version="1.1" id="Icons" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
                                viewBox="0 0 32 32" xmlSpace="preserve"
                            >
                                <style type="text/css">{`
                                    .st0{fill:none;stroke:#000000;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}
                                    .st1{fill:none;stroke:#000000;stroke-width:2;stroke-linejoin:round;stroke-miterlimit:10;}
                                `}
                                </style>
                                <circle className="st0" cx="25" cy="20" r="5"/>
                                <path className="st0" d="M26,25h-2l-2.8,2.2c-0.7,0.6-0.3,1.8,0.6,1.8h6.3c0.9,0,1.4-1.2,0.6-1.8L26,25z"/>
                                <circle className="st0" cx="25" cy="20" r="1"/>
                                <polyline className="st0" points="6,16 6,5 26,5 26,12 "/>
                                <polyline className="st0" points="17,19 5,19 3,23 17,23 "/>
                            </svg>
                        </div>

                        <div className="item_process_title"><Trans>Overenie v prehliadači prostredníctvom aktuálneho zariadenia.</Trans></div>

                        <button 
                            type="submit" 
                            className="btn_basic bordered" 
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()

                                dispatch(setStateStore(EStateActions.GO_NEXT, true))
                                dispatch(setStateStore(EStateActions.SET_VERIFICATION_TYPE, EVerificationType.DESKTOP))
                            }}
                        >
                            <span className="text">
                                <Trans>Pokračovať</Trans>
                            </span>
                        </button>   
                    </motion.div>

                    <motion.div 
                        className="item_process" 
                        initial={{ 
                            opacity: 0,
                            x: -10
                        }}
                        animate={{ 
                            opacity: 1, 
                            x: 0,
                            transition: {
                                duration: 1,
                                delay: .5
                            }
                        }}
                    >
                        <div className="icon2 mid">
                            <svg fill="#000000" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
                                viewBox="0 0 512 512" xmlSpace="preserve">
                                <g>
                                    <g>
                                        <path d="M373.21,0H138.79c-23.665,0-42.917,19.252-42.917,42.916v426.168c0,23.664,19.252,42.916,42.917,42.916h234.422
                                            c23.664,0,42.916-19.252,42.916-42.916V42.916C416.128,19.252,396.875,0,373.21,0z M395.729,469.084
                                            c0,12.417-10.101,22.518-22.519,22.518H138.79c-12.418,0-22.519-10.101-22.519-22.518v-54.996h279.458V469.084z M395.729,393.689
                                            H116.271V71.394h279.458V393.689z M395.729,50.996H116.271v-8.08c0-12.417,10.101-22.518,22.519-22.518h234.422
                                            c12.417,0,22.518,10.101,22.518,22.518V50.996z"/>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path d="M258.04,422.247h-4.08c-16.872,0-30.598,13.726-30.598,30.598c0,16.872,13.726,30.598,30.598,30.598h4.08
                                            c16.872,0,30.598-13.726,30.598-30.598C288.637,435.973,274.911,422.247,258.04,422.247z M258.04,463.044h-4.08
                                            c-5.624,0-10.199-4.575-10.199-10.199c0-5.624,4.575-10.199,10.199-10.199h4.08c5.624,0,10.199,4.575,10.199,10.199
                                            C268.239,458.469,263.664,463.044,258.04,463.044z"/>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path d="M228.234,135.649h-49.748c-5.633,0-10.199,4.567-10.199,10.199v49.748c0,5.632,4.566,10.199,10.199,10.199
                                            c5.633,0,10.199-4.567,10.199-10.199v-39.548h39.548c5.633,0,10.199-4.567,10.199-10.199
                                            C238.433,140.217,233.867,135.649,228.234,135.649z"/>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path d="M228.234,290.677h-39.548v-39.548c0-5.632-4.566-10.199-10.199-10.199c-5.633,0-10.199,4.567-10.199,10.199v49.748
                                            c0,5.632,4.566,10.199,10.199,10.199h49.748c5.633,0,10.199-4.567,10.199-10.199C238.433,295.245,233.867,290.677,228.234,290.677
                                            z"/>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path d="M333.514,135.649h-49.748c-5.633,0-10.199,4.567-10.199,10.199c0,5.632,4.566,10.199,10.199,10.199h39.548v39.548
                                            c0,5.632,4.566,10.199,10.199,10.199c5.633,0,10.199-4.567,10.199-10.199v-49.748
                                            C343.713,140.217,339.147,135.649,333.514,135.649z"/>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path d="M333.514,240.93c-5.633,0-10.199,4.567-10.199,10.199v39.548h-39.548c-5.633,0-10.199,4.567-10.199,10.199
                                            c0,5.632,4.566,10.199,10.199,10.199h49.748c5.633,0,10.199-4.567,10.199-10.199v-49.748
                                            C343.713,245.497,339.147,240.93,333.514,240.93z"/>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path d="M293.737,365.131h-7.139c-5.633,0-10.199,4.567-10.199,10.199s4.566,10.199,10.199,10.199h7.139
                                            c5.633,0,10.199-4.567,10.199-10.199S299.37,365.131,293.737,365.131z"/>
                                    </g>
                                </g>
                                <g>
                                    <g>
                                        <path d="M375.331,365.131h-42.837c-5.633,0-10.199,4.567-10.199,10.199s4.566,10.199,10.199,10.199h42.837
                                            c5.633,0,10.199-4.567,10.199-10.199S380.964,365.131,375.331,365.131z"/>
                                    </g>
                                </g>
                            </svg>
                        </div>

                        <div className="item_process_title"><Trans>Overenie prostredníctvom mobilu</Trans></div>

                        <button 
                            type="submit" 
                            className="btn_basic bordered" 
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()

                                dispatch(setStateStore(EStateActions.GO_MOBILE, true))
                                dispatch(setStateStore(EStateActions.SET_VERIFICATION_TYPE, EVerificationType.MOBILE))
                            }}
                        >
                            <span className="text">
                                <Trans>Zobraziť QR</Trans>
                            </span>
                        </button>  
                    </motion.div>
                </div>
            </form>
            
        </div>
    )
}
