import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/index';
import { ECardSideType, EMessageType, EState, EVerificationJournalMessage, ErrorType, ICardConfigData, IMessageCurrentState, IWSMessageDataDetectStart, IWSMessageDataDetectSuccess } from '../../../types';
import { EWSActions, setWsStore } from '../../../reducers/ws';
import { logInfo } from '../../helpers/logger';
import ScreenCapture from './ScreenCapture';
import uploadCard from '../../../reducers/api/uploadCard';
import { EStateActions, setStateStore } from '../../../reducers/state';
import getInfo from '../../../reducers/api/getInfo';
import { addVerificationJournalData } from '../../../reducers/api/addVerificationJournalData';
import { send } from '@giantmachines/redux-websocket';

interface IProps {
    videoRef: React.RefObject<HTMLVideoElement>,
    debug: boolean,
    sendErrorMessage: (error: ErrorType) => void,
    sideType: ECardSideType
    hideBack?: boolean
}

const ScreenCardDetect: React.FC<IProps> = ({
    videoRef,
    debug,
    sendErrorMessage,
    sideType,
    hideBack
}) => {
    const dispatch = useAppDispatch()
        
    const [loading, setLoading] = useState(false)
    const currentVerification = useAppSelector(store => store.stateStore.currentVerification)
    const verificationToken = useAppSelector(store => store.stateStore.verificationToken)
    const selectedCard = useAppSelector(store => store.stateStore.selectedCard)
    const selectedCardKey = useAppSelector(store => store.stateStore.selectedCardKey)
    const readedCardType = useAppSelector(store => store.stateStore.readedCardType)

    useEffect(() => {
        if(sideType && selectedCard && selectedCardKey) {
            addVerificationJournalData(
                verificationToken,
                EVerificationJournalMessage.snapStarted,
                {
                    side: sideType,
                    card: selectedCard
                } as IWSMessageDataDetectStart
            )
        }
    }, [verificationToken, sideType, selectedCard, selectedCardKey])

    useEffect(() => {
        let sendWS: IMessageCurrentState = {
            type: EMessageType.currentState,
            state: EState.detectCard,
            data: {
                side: sideType,
                card: selectedCard
            }
        }

        dispatch(send(sendWS))
    }, [dispatch, selectedCard, sideType])

    if(!selectedCard || !currentVerification) {
        return null
    }
    
    return (
        <ScreenCapture
            key={sideType}
            videoRef={videoRef}
            sideType={sideType}
            hideBack={hideBack}
            debug={debug}
            goBack={() => {
                dispatch(setStateStore(EStateActions.GO_PREV, null))
            }}
            onCapture={(image, descriptors, fallback, fallBackSide, onError) => {
                setLoading(true)

                if(descriptors.length) {
                    dispatch(setStateStore(EStateActions.SET_FACE_DESCRIPTORS, descriptors))
                }

                uploadCard(
                    image.image,
                    'jpg',
                    sideType,
                    selectedCard.name,
                    image.stats.descriptor
                ).then(res => {
                    if(res) {
                        console.log('uploadCard response:', res)

                        const wsMessage: IMessageCurrentState = {
                            type: EMessageType.currentState,
                            state: EState.detectCardSuccess,
                            data: {
                                side: sideType,
                            } as IWSMessageDataDetectSuccess
                        }
                        
                        dispatch(send(wsMessage))

                        addVerificationJournalData(
                            verificationToken,
                            EVerificationJournalMessage.snapProcessed,
                            {
                                side: sideType,
                                card: selectedCardKey,
                                cardType: res.card_type,
                                confidence: res.confidence,
                                detectedCard: res.detectedCard,
                                detectedBackendType: res.detectedBackendType,
                                detectedSide: res.detectedSide,
                                hasDetectedFace: res.ssdDescriptor?true:false,
                            }
                        )

                        let wasBadCard = false
                        
                        if(
                            res.detectedCard === selectedCard.name && 
                            res.detectedBackendType &&
                            (
                                readedCardType === null ||
                                readedCardType === res.detectedBackendType
                            )
                        ) {
                            if(res.detectedSide !== sideType) {
                                fallBackSide()
                                
                                addVerificationJournalData(
                                    verificationToken,
                                    EVerificationJournalMessage.badSide,
                                    {
                                        expected: sideType,
                                        detected: res.detectedSide
                                    }
                                )
                            } else {
                                dispatch(setWsStore(EWSActions.CLEAN_WS, null))
                                
                                dispatch(setStateStore(EStateActions.SET_UPLOADED_FILENAME, {sideType: sideType, filename: res.fileName}))
                                dispatch(setStateStore(EStateActions.SET_READED_CARD_TYPE, res.detectedBackendType))
        
                                const cards: {
                                    data: ICardConfigData,
                                    key: string
                                }[] = []

                                Object.keys(currentVerification.configurations.process).forEach(k => {
                                    const c = currentVerification.configurations.process[k]
                                    
                                    cards.push({key: k, data: c})
                                })

                                const selectedCard = cards.find(c => c.data.name === res.detectedCard)

                                if(selectedCard) {
                                    getInfo(
                                        res.fileName,
                                        sideType,
                                        res.detectedBackendType,
                                        selectedCard?.data.readParts
                                    ).then(resp => {
                                        if(resp) {
                                            let texts = resp.texts

                                            logInfo('Front side info response:', texts)

                                            dispatch(setStateStore(EStateActions.SET_TEXTS, texts))
                                            dispatch(setStateStore(EStateActions.GO_NEXT, null))
                                                        
                                            addVerificationJournalData(
                                                verificationToken,
                                                EVerificationJournalMessage.snapFinished,
                                                {
                                                    side: sideType,
                                                    card: selectedCard.key,
                                                    message: resp.message,
                                                    texts: texts
                                                },
                                                {
                                                    card: res.fileName
                                                }
                                            )
                                        }
                                    })
                                } else { 
                                    fallback()
                                    wasBadCard = true
                                }
                            }
                        } else {
                            fallback()
                            wasBadCard = true
                        }

                        if(wasBadCard) {
                            addVerificationJournalData(
                                verificationToken,
                                EVerificationJournalMessage.badCard,
                                {
                                    expected: selectedCard.name,
                                    detected: res.detectedCard,
                                    detectedBackendType: res.detectedBackendType
                                },
                                {
                                    card: res.fileName
                                }
                            )
                        }
                    } else {
                        dispatch(setStateStore(EStateActions.SET_UPLOADED_FILENAME, {sideType: sideType, filename: null}))
                        onError()
                    }
                    setLoading(false)
                })
            }}
            sendErrorMessage={sendErrorMessage}
            loading={loading}
        />
    )
}

export default ScreenCardDetect
