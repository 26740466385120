import {  useEffect } from 'react';
import { useAppSelector } from '../../../store';
import { EMessageType, EState } from '../../../types';
import { useDispatch } from 'react-redux';
import { EStateActions, setStateStore } from '../../../reducers/state';

interface IProps {
}

export const CancelListener: React.FC<IProps> = () => {
    const dispatch = useDispatch()

    const isMobile = useAppSelector(store => store.stateStore.isMobileVerification)
    const message = useAppSelector(store => store.wsStore.message)

    useEffect(() => {
        if(message?.type === EMessageType.currentState && isMobile) {
            if(message.state === EState.canceledFromDesktop) {
                dispatch(setStateStore(EStateActions.GO_CANCELED_DESKTOP, null))
            }
        }
    }, [dispatch, isMobile, message])

    return (                  
        <></>
    )
}
