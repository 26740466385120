import { t } from '@lingui/macro';
import {  useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../../../store';
import { EMessageType, EState, IWSMessageDataDetectStart, IWSMessageDataDetectSuccess, IWSMessageDataFinish } from '../../../types';
import { IconLoading } from '../icons/IconLoading';

interface IHistory {
    message: string,
    time: number,
}

interface IProps {
    onSuccessFinish: (data: IWSMessageDataFinish) => void
}

export const MobileHistory: React.FC<IProps> = ({onSuccessFinish}) => {
    const ref = useRef<HTMLDivElement>(null)
    const message = useAppSelector(store => store.wsStore.message)
    const [loading, setLoading] = useState(false)

    const [history, setHistory] = useState<IHistory[]>([{
        message: t`Čakám na spustenie overenia na mobilnom zariadení...`,
        time: Date.now()
    }])

    useEffect(() => {
        if(message?.type === EMessageType.detectStart) {
            if(message.variation === 'qr') {
                setHistory(oH => [...oH, {
                    message: t`Overenie na mobilnom zariadení spustené`,
                    time: Date.now()
                }])
            }
        }

        if(message?.type === EMessageType.currentState) {
            if(message.state === EState.detectCard) {
                const data = message.data as IWSMessageDataDetectStart

                const sideText = data.side === 'front'?t`prednej strany`:t`zadnej strany`

                setHistory(oH => [...oH, {
                    message: t`Prebieha detekcia` + ' ' + sideText,
                    time: Date.now()
                }])
            }

            if(message.state === EState.detectCardSuccess) {
                const data = message.data as IWSMessageDataDetectSuccess

                const sideText = data.side === 'front'?t`prednej strany`:t`zadnej strany`

                setHistory(oH => [...oH, {
                    message: (t`Detekcia ##side## úspešná`).replaceAll('##side##', sideText),
                    time: Date.now()
                }])
            }

            if(message.state === EState.face) {
                setHistory(oH => [...oH, {
                    message: t`Prebieha biometrické overenie používateľa`,
                    time: Date.now()
                }])
            }

            if(message.state === EState.faceSuccess) {
                setHistory(oH => [...oH, {
                    message: t`Biometrické overenie používateľa úspešné`,
                    time: Date.now()
                }])
                setLoading(true)
            }

            if(message.state === EState.confirm) {
                setHistory(oH => [...oH, {
                    message: t`Overenie úspešné, overte prečítané údaje.`,
                    time: Date.now()
                }])

                onSuccessFinish(message.data as IWSMessageDataFinish)
            }

            if(message.state === EState.canceled) {
                setHistory(oH => [...oH, ...[
                    {
                        message: t`Overenie zrušené`,
                        time: Date.now()
                    },
                    {
                        message: t`Čakám na spustenie overenia na mobilnom zariadení...`,
                        time: Date.now()
                    }
                ]])
            }
        }
    }, [onSuccessFinish, message])

    useEffect(() => {
        if(ref.current) {
            ref.current.scrollTop = ref.current.scrollHeight
        }
    }, [history])

    return (                  
        <div className="history-table-wrapper" ref={ref}>
            <IconLoading show={loading}/>
            <div className="history">
                {history.map((h, i) => {
                    return (
                        <div key={i} className="history_item">
                            <div className="history_time">
                                <span>
                                    {new Date(h.time).toLocaleTimeString()}
                                </span>
                            </div>
                            <div className="history_message">{h.message}</div>
                        </div>
                    )    
                })}
            </div>
        </div>
    )
}
