import React from "react";
import { useAppDispatch } from '../../../store/index';
import MainNavigation from "../global/MainNavigation";
import { CameraSwitch } from "../functional/CameraSwitch";
import { t } from '@lingui/macro';
import { EWSActions, setWsStore } from "../../../reducers/ws";
import { connect } from "@giantmachines/redux-websocket";
import config from "../../../config";

interface IProps {}

export const ScreenSocketError:React.FC<IProps> = () => {
    const dispatch = useAppDispatch()

    const retryConnection = () => {
        dispatch(setWsStore(EWSActions.CLEAN_WS, null))
        dispatch(connect(config.ws))
    }

    return (
        <>
            <MainNavigation/>
            <div className="box_video nocamera">
                <div className="box_video_inner">
                    <div className="box_video_content">

                        <img src="/img/layout/novideo.png" alt="" className="box_video_image" />
                        
                        <div className="box_video_cover"></div>
                        <style>
                            {`
                                .box_video{
                                    --h-per: 56%;
                                    --w-per: 56%;
                                    --w-m-per: 88%;
                                    --h-m-per: 44%;
                                }
                            `}
                        </style>

                        <div className="box_video_frame">
                        </div>

                        <div className="box_video_bottom">
                            <div className="box_video_semi box_video_text w100p">
                                <span className="icon type2">
                                    <svg viewBox="0 0 21 21" className="sicofill" xmlns="http://www.w3.org/2000/svg">
                                        <use href="/img/layout/icons.svg#s_cross" />
                                    </svg>
                                </span>
                                <span>{t`Ospravedlňujeme sa, systém je dočasne nedostupný.`}</span>
                            </div>
                        </div>
                    </div>
                </div>            
            </div>
           
            <div className="main_bottom">
                <div className="box_buttons">
                    <div className="box_buttons_left">
                        <button 
                            className="btn_basic more"
                            onClick={() => {
                                retryConnection()
                            }}
                        >
                            <span className="arrow">
                                <svg className="sicofill" viewBox="0 0 21 11" xmlns="http://www.w3.org/2000/svg">
                                    <use href="/img/layout/icons.svg#s_ar_l" />
                                </svg>   
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
