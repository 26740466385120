import { Reducer } from 'redux'
import { IWSMessage } from '../types'

export interface IWSStore {
    showMessage: boolean,
    isError: boolean,
    connected: boolean,
    initialized: boolean,
    message?: IWSMessage
}

export enum EWSActions {
    CONNECT = 'REDUX_WEBSOCKET::CONNECT',
    OPEN = 'REDUX_WEBSOCKET::OPEN',
    MESSAGE = 'REDUX_WEBSOCKET::MESSAGE',
    CLOSED = 'REDUX_WEBSOCKET::CLOSED',
    ERROR = 'REDUX_WEBSOCKET::ERROR',
    CLEAN_WS = 'CLEAN_WS',
    SHOW_MESSAGE = 'SHOW_MESSAGE',
    DISCONNECT = 'REDUX_WEBSOCKET::DISCONNECT',
    INTIIALIZED = 'INITIALIZED'
}

const initialState: IWSStore = {
    isError: false,
    connected: false,
    initialized: false,
    showMessage: false
}

export function setWsStore(type: EWSActions, value: any) {
    return { type: type, payload: value}
}

const reducer: Reducer<IWSStore> = (state = initialState, action) => {
    //if(action.type.startsWith('REDUX_WEBSOCKET::')) {
        // console.log(action)
    //}

    switch (action.type) {
        case EWSActions.MESSAGE: 
            let message: IWSMessage = action.payload.message
            try {
                message = JSON.parse(action.payload.message)
            } catch(e) {}
            
            if (message && message.type !== 'srv_busy') {
                state = {
                    ...state,
                    message: message
                }
            }

            break
        case EWSActions.CLEAN_WS:
            state = {
                ...state,
                message: undefined
            }
            break
        case EWSActions.SHOW_MESSAGE:
            state = {
                ...state,
                showMessage: action.payload
            }
            break
        case EWSActions.INTIIALIZED:
            state = {
                ...state,
                initialized: true
            }
            break
        case EWSActions.CONNECT:
            state = {
                ...state,
                connected: false,
                isError: false
            }
            break
        case EWSActions.DISCONNECT:
            state = {
                ...state,
                connected: false,
                isError: false,
                initialized: false
            }
            break
        case EWSActions.OPEN:
            state = {
                ...state,
                connected: true
            }
            break
        case EWSActions.CLOSED:
            state = {
                ...state,
                connected: false,
                isError: false,
                initialized: false
            }
            break
        case EWSActions.ERROR:
            state = {
                ...state,
                connected: false,
                isError: true,
                initialized: false
            }

            console.error(action.payload)
            break
    }

    return state
}

export { reducer as wsReducer }
